// import React from "react";
import { guides } from "./Guides";

export const Guide = ({
  close,
  details,
}: //   refetch,
{
  close?: () => void;
  details: Integration;
  refetch: () => void;
}) => {
  const guideKey = details.slug.startsWith("google-")
    ? "google"
    : details.slug.startsWith("microsoft-")
    ? "microsoft"
    : details.slug.startsWith("zoho-")
    ? "zoho-workdrive"
    : details.slug;
  const currentGuide = guides[guideKey] || [];

  return (
    <div className="w-full flex flex-col">
      <header className="flex items-start w-full justify-between border-b py-4 px-5">
        <div className="flex flex-col gap-y-1  max-w-[440px]">
          <h4 className="text-[#0A0D14] font-medium">
            How to Integrate {details.name} to Wholistic
          </h4>
          <span className="text-sm text-[#525866]">{details.description}</span>
        </div>
        <button onClick={close}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 8.93906L13.7126 5.22656L14.7731 6.28706L11.0606 9.99956L14.7731 13.7121L13.7126 14.7726L10.0001 11.0601L6.28755 14.7726L5.22705 13.7121L8.93955 9.99956L5.22705 6.28706L6.28755 5.22656L10.0001 8.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <section className="p-5 flex flex-col w-full gap-y-5">
        {currentGuide.map((step: string, index: number) => (
          <div key={index} className="flex flex-row">
            <span className="bg-[#F6F8FA] first:rounded-lg last:rounded-lg text-sm text-[#525866] font-bold py-2 px-3 relative min-w-fit max-w-fit">
              Step {index + 1}
            </span>
            <p className="bg-[#F6F8FA] first:rounded-lg last:rounded-lg text-sm text-[#525866] font-normal py-2 px-3 relative  max-w-fit">
              {step}
            </p>
          </div>
        ))}
      </section>

      {/* <footer className="px-5 py-4 border-t flex justify-end items-center gap-x-3">
        <Button mono label="Cancel" effect={close} />
        <Button
        onClick={handlePopup}
        //   label="Connect app"
        //   effect={mutate}
        //   loading={isPending}
        //   disabled={!formData.clientId || !formData.clientSecret}
        />
      </footer> */}
    </div>
  );
};
