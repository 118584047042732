import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ActivateOrganization,
  DeactivateOrganization,
  DeleteOrganization,
} from "~/actions";
import { ExpandableButton, Group, Pill, Table } from "~/components";
import Loader from "~/components/UI/Loader";
import { openModal } from "~/providers";
import { adminGetWorkspace } from "~/queries/workspaceQueries";
import { getTransactions } from "~/queries/transactionQueries";
import { billingPeriod } from "~/utils/dateUtils";

export const OrganizationPage = () => {
  const searchParams = useSearchParams()[0];
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const navigate = useNavigate();
  const { id } = useParams();
  const popup = openModal();

  const { data: workspace, isLoading } = useQuery<Workspace>({
    queryKey: ["workspace", id],
    queryFn: async () => {
      try {
        const data = await adminGetWorkspace(id);

        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  console.log(workspace);

  const { data: transactions } = useQuery({
    queryKey: ["transactions", { page, limit, id }],
    queryFn: async () => {
      try {
        const data = await getTransactions({
          page,
          limit,
          "filter[workspaceId]": id,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const transactionData = transactions?.data || [];

  const organization = {
    name: workspace?.name,
    id: workspace?.id,
    user: {
      profile: {
        firstName: workspace?.primaryUser?.profile?.firstName,
        lastName: workspace?.primaryUser?.profile?.lastName,
      },
    },
    dateCreated: workspace?.createdAt,
    email: workspace?.primaryUser.email,
    status: workspace?.status,
  };
  // const isLoading = false;

  const details = [
    { title: "Workspace Name", value: organization?.name },
    {
      title: "Created By",
      value: `${organization?.user.profile.firstName} ${organization?.user?.profile?.lastName}`,
    },
    { title: "Email address", value: organization?.email },
    { title: "Date created", value: organization.dateCreated },
    { title: "Status", value: organization?.status },
  ];

  const subscription = {
    name: workspace?.subscription?.plan,
    id,
    status: "active",
    plan: workspace?.subscription?.plan?.name,
    cost: `${workspace?.subscription?.currency === "USD" ? "$" : "₦"} ${
      workspace?.subscription?.amount
    } / ${workspace?.subscription?.plan?.interval} + tax`,
    features: ["Full Access", "Limited Access", "Limited Access"],
    billingPeriod: billingPeriod(workspace),
  };

  const subscriptionDetails = [
    { title: "Plan", value: subscription?.plan },
    { title: "Cost", value: subscription?.cost },
    // {
    //   title: 'Payment method',
    //   value: (
    //     <div className='flex flex-col gap-y-1'>
    //       {subscription.features.map((feature, index) => (
    //         <span className='text-sm' key={index}>
    //           Feature {index + 1}{' '}
    //           <span className='text-[#868C98]'>{feature}</span>
    //         </span>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      title: "Billing period",
      value: billingPeriod(workspace),
    },
  ];
  const handleUpdate = (action: "active" | "deactivate" | "delete") => {
    if (action === "active") {
      popup({
        component: <ActivateOrganization data={organization} />,
      });
    } else if (action === "deactivate") {
      popup({
        component: <DeactivateOrganization data={organization} />,
      });
    } else if (action === "delete") {
      popup({
        component: <DeleteOrganization data={organization} />,
      });
    }
  };

  const colums: ColumnDef<(typeof transactions.data)[0]>[] = [
    // {
    //   id: "workspace",
    //   cell: ({ row }) => (
    //     <span>{totoPascalCase(row.original.workspace?.name)}</span>
    //   ),
    //   header: () => <span>Organisation Name</span>,
    //   size: 10,
    // },

    {
      accessorFn: (row) => row.reference,
      id: "reference",
      cell: (info) => {
        const value = info.getValue() as string;
        return value.length > 15 ? value.slice(0, 15) + "..." : value;
      },
      header: () => <span>Reference</span>,
      size: 20,
    },
    {
      id: "amount",
      cell: ({ row }) => (
        <span className="flex w-full text-right ml-auto">
          {row.original.amount + " " + row.original.currency}{" "}
        </span>
      ),
      header: () => <span className="flex w-full text-right">Amount</span>,
      size: 26,
    },
    {
      id: "date",
      cell: ({ row }) => <span>{row.original.createdAt}</span>,
      header: () => <span>Timestamp</span>,
      size: 17,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      // size: 9.9,
    },
  ];
  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full h-full flex flex-col overflow-scroll p-8">
      <div className="flex items-center w-full justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-2 text-[#525866] text-sm font-medium"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
              fill="#525866"
            />
          </svg>
          <span>Back to Organisations</span>
        </button>
      </div>
      <Group key="header">
        <div className="flex items-center w-full justify-between mt-6">
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <h1 className="text-[#0A0D14] font-medium text-lg">
                {organization?.name?.toLocaleUpperCase()}
              </h1>
              <Pill status={organization?.status} />
            </div>
            <span className="text-sm text-[#525866]">
              Organisation ID: {organization?.id?.substring(0, 6)}
            </span>
          </div>
          <ExpandableButton label="Actions" mono>
            <div className="min-w-[144px] w-full flex flex-col items-start">
              {organization.status == "active" && (
                <button
                  onClick={() => handleUpdate("deactivate")}
                  className="kebab-button"
                >
                  Deactivate
                </button>
              )}
              {organization.status !== "active" && (
                <button
                  onClick={() => handleUpdate("active")}
                  className="kebab-button"
                >
                  Activate
                </button>
              )}
              <button
                style={{ color: "#DF1C41" }}
                onClick={() => handleUpdate("delete")}
                className="kebab-button"
              >
                Delete
              </button>
            </div>
          </ExpandableButton>
        </div>
      </Group>
      <div className="flex mt-6 items-center gap-x-5 w-full h-full">
        <Group key="left">
          <div className="w-[35%] h-full flex flex-col gap-y-5">
            <Group key="details">
              <div className="bg-white border p-4 rounded-xl border-[#E2E4E9] shadow-sm ">
                <header className="w-full flex items-center justify-between border-b pb-4">
                  <span className="font-medium">Organisation Information</span>
                </header>
                <div className="py-4 flex flex-col gap-y-4">
                  {details.map((d) => (
                    <div key={d.title} className="flex flex-col ">
                      <div className="text-xs text-[#525866]">{d.title}</div>
                      <div className="text-sm text-[#0A0D14]">{d.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Group>
            <Group key="Subscription Information">
              <div className="bg-white border p-4 rounded-xl border-[#E2E4E9] shadow-sm ">
                <header className="w-full flex items-center justify-between border-b pb-4">
                  <span className="font-medium">Subscription Information</span>
                </header>
                <div className="py-4 flex flex-col gap-y-4">
                  {subscriptionDetails.map((d) => (
                    <div key={d.title} className="flex flex-col ">
                      <div className="text-xs text-[#525866]">{d.title}</div>
                      <div className="text-sm text-[#0A0D14]">{d.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Group>
          </div>
        </Group>
        <Group key="right">
          <div className="w-full h-full">
            <h3 className="font-medium">Transactions</h3>
            {isLoading ? (
              <Loader big />
            ) : transactionData.length > 0 ? (
              <div className="mt-4 bg-white w-full h-full rounded-xl flex-col items-center justify-center ">
                <Table
                  // clickFunction={navigate}
                  key="transactions"
                  cols={colums}
                  rawData={transactionData}
                />
              </div>
            ) : (
              <div className="mt-4 bg-white w-full h-full rounded-xl flex flex-col items-center justify-center ">
                <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5  text-center">
                  <img src="/empty.svg" className="w-[100px]" />
                  <div className="flex flex-col items-center gap-y-2">
                    <span className=" text-sm text-[#0A0D14]">
                      No transactions to show
                    </span>
                    <span className=" text-sm text-[#525866]">
                      This plan currently does not have any transactions
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Group>
      </div>
    </div>
  );
};
