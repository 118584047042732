/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Group } from "../Group";
import { useMutation, useQuery } from "@tanstack/react-query";
import { askChat, getChatSuggestions } from "~/api";
import toast from "react-hot-toast";
import Loader from "../Loader";
import { useAuth } from "~/providers";
import { v4 } from "uuid";
import { fadeIn } from "~/constants/animations";
import markdownit from "markdown-it";
import doMarkdownit from "@digitalocean/do-markdownit";

import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";
const md = markdownit().use(doMarkdownit, {
  link_attributes: {
    attributes: {
      class: "text-[#0A0D14] underline",
      target: "_blank",
    },
  },
  table_wrapper: {
    // update this class name to wrap the table and allow it potentially scroll
    // https://github.com/digitalocean/do-markdownit?tab=readme-ov-file#table_wrapper
    className: "table-auto",
  },
  user_mention: false,
});

export const AIChat = () => {
  const { user } = useAuth();
  // eslint-disable-next-line prefer-const
  let { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [ishovering, setIshovering] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [question, setQuestion] = useState("");
  const freshId = useState(() => v4())[0];
  const listRef = useRef<HTMLDivElement>(null);

  const { data: suggestions, isLoading } = useQuery<{ message: string }[]>({
    enabled:
      isOpen &&
      isSubscribedFor("assistant-integration") &&
      isActionAllowed("list-assistant-chats"),
    queryKey: ["chat suggestions"],
    queryFn: async () => {
      try {
        const data = await getChatSuggestions();
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const appendMessage = (newMessage: any) => {
    setMessages((prevMessages: any[]) => {
      const existingIndex = prevMessages.findIndex(
        (m) => m.messageId === newMessage.messageId
      );

      if (existingIndex !== -1) {
        // Message exists, update it
        return prevMessages.map((m, index) =>
          index === existingIndex
            ? { ...m, message: m.message + (newMessage.message ?? "") }
            : m
        );
      } else {
        // Message doesn't exist, add it
        return [...prevMessages, newMessage];
      }
    });
  };
  const { mutate: ask, isPending } = useMutation({
    mutationFn: () =>
      askChat({ sessionId: freshId, message: question }, (message) => {
        appendMessage(JSON.parse(message.data));
      }),
    ...{
      onSettled() {
        setQuestion("");
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  const handleAsk = (e: any) => {
    e.preventDefault();

    setMessages((prev: any) => [
      ...prev,
      {
        recipientId: "assistant",
        senderId: user.id,
        message: question,
        messageId: new Date(),
      },
    ]);
    ask();
  };
  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages]);
  return (
    <div id="nav" className="fixed right-10 bottom-6 flex-col items-end z-[50]">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            layout
            // left-0   bottom-[27px]
            // -right-[90vw] bottom-0
            className="mb-5 w-[540px] origin-bottom-right bg-white rounded-2xl border border-[#E2E4E9] absolute right-0 bottom-[36px] h-[619px] z-[50] flex flex-col overflow-clip"
            initial={{ scaleY: 0, opacity: 0 }}
            transition={{
              scaleY: {
                type: "tween",
                ease: [0.4, 0.0, 0.2, 1],
                duration: 0.3,
              },
              opacity: { duration: 0.1 },
            }}
            animate={{ scaleY: 1, opacity: 1 }}
            exit={{
              scaleY: 0,
              opacity: 0,
              transition: {
                type: "tween",
                ease: [0.4, 0.0, 0.2, 1],
                duration: 0.3,
              },
              origin: "bottom-right",
            }}
          >
            <Group key="header">
              <header className="px-5 py-4 border-b rounded-t-2xl flex items-center w-full justify-between">
                <div className="flex items-center gap-x-2">
                  <img src="/coloredstars.svg" />
                  <span className="font-medium text-[#0A0D14]">
                    Wholistic AI
                  </span>
                </div>
                <button
                  className="fill-[#525866] hover:fill-[#DF1C41] duration-200 transition-colors"
                  onMouseDown={() => setIsOpen(false)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0001 10.9391L15.7126 7.22656L16.7731 8.28706L13.0606 11.9996L16.7731 15.7121L15.7126 16.7726L12.0001 13.0601L8.28755 16.7726L7.22705 15.7121L10.9396 11.9996L7.22705 8.28706L8.28755 7.22656L12.0001 10.9391Z"
                      fill="inherit"
                    />
                  </svg>
                </button>
              </header>
            </Group>
            {/* {isLoading ? (
              <Loader big />
            ) : chats?.data?.length > 0 ? (
              <div></div>
            ) : ( */}
            <div className="flex flex-col p-5 items-center gap-y-5 w-full h-full overflow-clip">
              <div className="overflow-clip flex-1 h-full w-full">
                {messages.length > 0 ? (
                  <div
                    ref={listRef}
                    className="h-fit max-h-[418px] overflow-scroll remove-scroll w-full"
                  >
                    <div className="flex flex-col gap-y-5 w-full overflow-clip max-h-full">
                      {messages.map((message, index) => (
                        <motion.div
                          variants={fadeIn}
                          initial="initial"
                          animate="animate"
                          key={index}
                          style={{
                            marginRight:
                              message.senderId == "assistant"
                                ? "auto"
                                : undefined,
                            marginLeft:
                              message.senderId != "assistant"
                                ? "auto"
                                : undefined,
                          }}
                          className="max-w-[80%] w-fit flex items-start gap-x-2"
                        >
                          {message.senderId == "assistant" && (
                            <img src="/coloredstars.svg" />
                          )}
                          <p className="p-2 rounded-lg flex-1 bg-[#F5F7FA] text-sm">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: md.render(message.message),
                              }}
                            />
                          </p>
                          {message.senderId !== "assistant" && (
                            <span className="w-8 h-8 shrink-0 rounded-full overflow-clip flex items-center justify-center text-sm font-semibold bg-[#f5f5f5] text-black">
                              {`${user.firstName?.[0] || ""}${
                                user?.lastName?.[0] || ""
                              }`}
                            </span>
                          )}
                        </motion.div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Group key="no message">
                    <div className="flex flex-col text-center items-center justify-end h-full w-full">
                      <h3 className="text-lg text-[#0A0D14] font-medium">
                        Ask anything with AI
                      </h3>
                      <p className="text-sm text-[#525866]">
                        Hi {user?.firstName} {user?.lastName}! Use Wholistic AI
                        to search for things or answer questions about projects
                        in Assurdly Gang.
                      </p>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <div className="flex flex-col mt-[73px] text-left w-full">
                          <span className="text-xs font-medium text-[#868C98]">
                            Ask about your projects
                          </span>
                          <ul className="flex flex-col gap-y-2 mt-2 text-left">
                            {suggestions && suggestions.length > 0 ? (
                              suggestions.map((sug) => (
                                <button
                                  key={sug.message}
                                  className="text-sm text-[#525866] text-left"
                                  onClick={(e) =>
                                    setQuestion(e.currentTarget.innerText)
                                  }
                                >
                                  {sug.message}
                                </button>
                              ))
                            ) : (
                              <>
                                {" "}
                                <button
                                  className="text-sm text-[#525866] text-left"
                                  onClick={(e) =>
                                    setQuestion(e.currentTarget.innerText)
                                  }
                                >
                                  What are the dependencies needed for Project
                                  Jarvis?
                                </button>
                                <button
                                  className="text-sm text-[#525866] text-left"
                                  onClick={(e) =>
                                    setQuestion(e.currentTarget.innerText)
                                  }
                                >
                                  Give me a summary of recent discussions around
                                  Project Jarvis
                                </button>
                              </>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Group>
                )}
              </div>
              <form
                onSubmit={handleAsk}
                className="w-full shrink-0 flex items-center gap-x-2 py-[10px] px-2 border border-[#E2E4E9] rounded-lg"
              >
                <input
                  onChange={(e) => setQuestion(e.target.value)}
                  value={question}
                  name="question"
                  placeholder="Ask a question or search for anything..."
                  className="outline-none text-sm text-[#525866] h-fit w-full resize-none break-words"
                  // rows={3}
                />
                <button
                  type="submit"
                  className="flex items-center justify-center shrink-0"
                >
                  {isPending ? (
                    <Loader />
                  ) : (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-3.93402e-07 9C-6.10561e-07 4.032 4.032 1.49269e-06 9 1.27553e-06C13.968 1.05837e-06 18 4.032 18 9C18 13.968 13.968 18 9 18C4.032 18 -1.76244e-07 13.968 -3.93402e-07 9ZM8.1 9L8.1 12.6L9.9 12.6L9.9 9L12.6 9L9 5.4L5.4 9L8.1 9Z"
                        style={{
                          fill: !question ? "#CDD0D5" : "#38c793",
                        }}
                      />
                    </svg>
                  )}
                </button>
              </form>
            </div>
            {/* )} */}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence key="popover">
        {" "}
        {ishovering && (
          <motion.div
            variants={fadeIn}
            initial={"initial"}
            animate="animate"
            exit="exit"
            className="absolute bottom-[50px] right-0  ai-btn text-[#ffffff] text-sm font-medium rounded-xl w-[180px]"
          >
            <div className=" flex flex-col  w-full h-full backdrop-brightness-90 px-4 py-2 rounded-xl gap-y-1">
              <h6>Ask AI</h6>
              <span className="text-xs text-[#fffff6] font-normal">
                Ask your smart assistant to do what it does best
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        key="menu"
        onHoverStart={() => setIshovering(isOpen ? false : true)}
        onHoverEnd={() => setIshovering(false)}
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIshovering(false);
        }}
        className="py-[10px] px-[12px] text-white rounded-lg backdrop-blur-md flex items-center ai-btn gap-x-1"
      >
        <img src="/stars.svg" />
      </motion.button>
    </div>
  );
};
