import { useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Header } from "~/components";
import { useForm } from "~/hooks";
import { SettingContainer } from "../../../components";
import toast from "react-hot-toast";
import { getSettings } from "~/queries/settingsQueries";
import { updateSettings } from "~/mutations/securityMutation";
import {
  convertToMilliseconds,
  TimeUnit,
  convertToDisplayValue,
} from "~/utils/dateUtils";
import { FormData } from "~/utils/helpers";

export const Security = () => {
  const qc = useQueryClient();
  const { data: settingsData, isLoading } = useQuery({
    queryKey: ["settings"],
    queryFn: async () => {
      try {
        const data = await getSettings();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { formData, update, setData } = useForm<FormData>({
    initial: {},
  });

  useEffect(() => {
    if (settingsData) {
      const initialData = {
        inactivityTimeout: convertToDisplayValue(
          settingsData.inactivityTimeout
        ),
        requestTimeout: convertToDisplayValue(settingsData.requestTimeout),
        authTokenValidity: convertToDisplayValue(
          settingsData.authTokenValidity
        ),
        passwordResetTokenValidity: convertToDisplayValue(
          settingsData.passwordResetTokenValidity
        ),
        setupTokenValidity: convertToDisplayValue(
          settingsData.setupTokenValidity
        ),
        maxAuthAttempts: settingsData.maxAuthAttempts,
        maxAuthAttemptValidity: convertToDisplayValue(
          settingsData.maxAuthAttemptValidity
        ),
      };
      setData(initialData);
    }
  }, [settingsData, setData]);

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      const convertedData = Object.entries(formData).reduce(
        (acc, [key, value]) => {
          if (
            typeof value === "object" &&
            "value" in value &&
            "unit" in value
          ) {
            acc[key] = convertToMilliseconds(
              value.value,
              value.unit as TimeUnit
            );
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, number>
      );
      return updateSettings(convertedData);
    },
    onSuccess() {
      toast.success("Settings successfully updated");
      qc.invalidateQueries({ queryKey: ["settings"] });
    },
    onError(err: any) {
      toast.error(err.message);
    },
  });

  const settings = [
    {
      title: "Inactivity Timeout",
      subtext:
        "The time period after which a user will be automatically logged out due to inactivity.",
      name: "inactivityTimeout",
    },
    {
      title: "Request Timeout",
      subtext:
        "The maximum duration the system will wait for a response when making external requests.",
      name: "requestTimeout",
    },
    {
      title: "Auth Token Expiration Duration",
      subtext: "The duration for which an authentication token remains valid.",
      name: "authTokenValidity",
    },
    {
      title: "Password Reset Token Expiration Duration",
      subtext: "The duration for which a password reset token remains valid.",
      name: "passwordResetTokenValidity",
    },
    {
      title: "Invitation Token Expiration Duration",
      subtext: "The duration for which an invitation token remains valid.",
      name: "setupTokenValidity",
    },
    {
      title: "Failed Login Attempts",
      subtext:
        "The maximum number of failed login attempts before an account is locked.",
      name: "maxAuthAttempts",
    },
    {
      title: "Failed Login Attempts Lockout Duration",
      subtext: "The duration for which a user would be locked out",
      name: "maxAuthAttemptValidity",
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full flex flex-col">
      <Header
        title="General Settings"
        subtext="Manage your workspace general settings."
      />
      <div className="rounded-xl w-full border border-[#E2E4E9] mt-6">
        <div className="bg-white rounded-xl px-5 flex-col">
          {settings.map((s) => (
            <SettingContainer key={s.name} title={s.title} subtext={s.subtext}>
              <div className="w-[50%]">
                <div className="bg-transparent border w-full p-2 rounded-xl flex items-center justify-between">
                  <input
                    className="text-sm outline-none w-full"
                    value={
                      s.name === "maxAuthAttempts"
                        ? formData[s.name]
                        : formData[s.name]?.value
                    }
                    name={
                      s.name === "maxAuthAttempts" ? s.name : `${s.name}.value`
                    }
                    required
                    onChange={(e) => {
                      if (s.name === "maxAuthAttempts") {
                        update({
                          target: {
                            name: s.name,
                            value: Number(e.target.value),
                          },
                        });
                      } else {
                        update({
                          target: {
                            name: s.name,
                            value: {
                              ...formData[s.name],
                              value: Number(e.target.value),
                            },
                          },
                        });
                      }
                    }}
                    type="number"
                  />
                  {s.name !== "maxAuthAttempts" && (
                    <select
                      className="outline-none text-sm shrink-0 border-l border-[#E2E4E9]"
                      name={`${s.name}.unit`}
                      required
                      onChange={(e) =>
                        update({
                          target: {
                            name: s.name,
                            value: {
                              ...formData[s.name],
                              unit: e.target.value,
                            },
                          },
                        })
                      }
                      value={formData[s.name]?.unit ?? "ms"}
                    >
                      <option value="ms">ms</option>
                      <option value="secs">secs</option>
                      <option value="mins">mins</option>
                      <option value="hours">hours</option>
                      <option value="days">days</option>
                    </select>
                  )}
                </div>
              </div>
            </SettingContainer>
          ))}

          <div className="w-full flex justify-end py-5">
            <Button
              label="Save changes"
              type="submit"
              disabled={!formData || isPending}
              onClick={() => mutate()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
