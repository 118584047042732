import { NavLink, useLocation } from "react-router-dom";
import { ProfileDropdown } from "./components";
import { AnimatePresence, motion } from "framer-motion";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";
import { useAuth } from "~/providers";
import { useEffect, useState } from "react";

export const Navbar = ({ show }: { show: boolean }) => {
  const { role, workspace } = useAuth();
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("isCollapsed") === "true"
  );
  useEffect(() => {
    localStorage.setItem("isCollapsed", isCollapsed.toString());
  }, [isCollapsed]);
  const links = [
    {
      path: "/app/inbox",
      label: "Inbox",
      show: role?.slug?.toLowerCase() !== "platform-owner",
      end: false,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.75 3H16.25C16.4489 3 16.6397 3.07902 16.7803 3.21967C16.921 3.36032 17 3.55109 17 3.75V15.75C17 15.9489 16.921 16.1397 16.7803 16.2803C16.6397 16.421 16.4489 16.5 16.25 16.5H2.75C2.55109 16.5 2.36032 16.421 2.21967 16.2803C2.07902 16.1397 2 15.9489 2 15.75V3.75C2 3.55109 2.07902 3.36032 2.21967 3.21967C2.36032 3.07902 2.55109 3 2.75 3ZM15.5 11.25H12.938C12.6462 11.9191 12.1655 12.4885 11.5548 12.8884C10.9441 13.2883 10.23 13.5013 9.5 13.5013C8.77003 13.5013 8.05592 13.2883 7.44523 12.8884C6.83454 12.4885 6.35382 11.9191 6.062 11.25H3.5V15H15.5V11.25ZM15.5 9.75V4.5H3.5V9.75H7.25C7.25 10.3467 7.48705 10.919 7.90901 11.341C8.33097 11.7629 8.90326 12 9.5 12C10.0967 12 10.669 11.7629 11.091 11.341C11.5129 10.919 11.75 10.3467 11.75 9.75H15.5Z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app",
      label: "Dashboard",
      show: true,
      end: true,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.75 16.75V9.25H16.75V16.75H10.75ZM3.25 10.75V3.25H9.25V10.75H3.25ZM7.75 9.25V4.75H4.75V9.25H7.75ZM3.25 16.75V12.25H9.25V16.75H3.25ZM4.75 15.25H7.75V13.75H4.75V15.25ZM12.25 15.25H15.25V10.75H12.25V15.25ZM10.75 3.25H16.75V7.75H10.75V3.25ZM12.25 4.75V6.25H15.25V4.75H12.25Z"
            fill="inherit"
          />
        </svg>
      ),
    },

    {
      path: "/app/clients",
      end: false,
      label: "Clients",
      show:
        isActionAllowed("view-client") &&
        role?.slug?.toLowerCase() !== "project-team-member" &&
        isSubscribedFor("clients"),
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.75 16H15.25V17.5H4.75V16ZM10 14.5C8.4087 14.5 6.88258 13.8679 5.75736 12.7426C4.63214 11.6174 4 10.0913 4 8.5C4 6.9087 4.63214 5.38258 5.75736 4.25736C6.88258 3.13214 8.4087 2.5 10 2.5C11.5913 2.5 13.1174 3.13214 14.2426 4.25736C15.3679 5.38258 16 6.9087 16 8.5C16 10.0913 15.3679 11.6174 14.2426 12.7426C13.1174 13.8679 11.5913 14.5 10 14.5ZM10 13C11.1935 13 12.3381 12.5259 13.182 11.682C14.0259 10.8381 14.5 9.69347 14.5 8.5C14.5 7.30653 14.0259 6.16193 13.182 5.31802C12.3381 4.47411 11.1935 4 10 4C8.80653 4 7.66193 4.47411 6.81802 5.31802C5.97411 6.16193 5.5 7.30653 5.5 8.5C5.5 9.69347 5.97411 10.8381 6.81802 11.682C7.66193 12.5259 8.80653 13 10 13Z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app/projects",
      end: false,
      label: "Projects",
      show: isActionAllowed("view-project") && isSubscribedFor("projects"),
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.25 16.75C3.05109 16.75 2.86032 16.671 2.71967 16.5303C2.57902 16.3897 2.5 16.1989 2.5 16V4C2.5 3.80109 2.57902 3.61032 2.71967 3.46967C2.86032 3.32902 3.05109 3.25 3.25 3.25H8.8105L10.3105 4.75H16C16.1989 4.75 16.3897 4.82902 16.5303 4.96967C16.671 5.11032 16.75 5.30109 16.75 5.5V7.75H15.25V6.25H9.6895L8.1895 4.75H4V13.7485L5.125 9.25H17.875L16.1425 16.1823C16.1019 16.3444 16.0082 16.4884 15.8764 16.5913C15.7446 16.6941 15.5822 16.75 15.415 16.75H3.25ZM15.9535 10.75H6.2965L5.1715 15.25H14.8285L15.9535 10.75Z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app/requests",
      // show: true,
      show:
        isActionAllowed("list-project-requests") &&
        isSubscribedFor("project-requests"),
      end: false,
      label: "Requests",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.5 6.25V4C5.5 3.80109 5.57902 3.61032 5.71967 3.46967C5.86032 3.32902 6.05109 3.25 6.25 3.25H11.0605L12.5605 4.75H16.75C16.9489 4.75 17.1397 4.82902 17.2803 4.96967C17.421 5.11032 17.5 5.30109 17.5 5.5V13C17.5 13.1989 17.421 13.3897 17.2803 13.5303C17.1397 13.671 16.9489 13.75 16.75 13.75H14.5V16C14.5 16.1989 14.421 16.3897 14.2803 16.5303C14.1397 16.671 13.9489 16.75 13.75 16.75H3.25C3.05109 16.75 2.86032 16.671 2.71967 16.5303C2.57902 16.3897 2.5 16.1989 2.5 16V7C2.5 6.80109 2.57902 6.61032 2.71967 6.46967C2.86032 6.32902 3.05109 6.25 3.25 6.25H5.5ZM5.5 7.75H4V15.25H13V13.75H5.5V7.75ZM7 4.75V12.25H16V6.25H11.9395L10.4395 4.75H7Z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app/organizations",
      end: false,
      show: workspace?.slug == "admin",
      label: "Organisations",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.75 16H18.25V17.5H1.75V16H3.25V3.25C3.25 3.05109 3.32902 2.86032 3.46967 2.71967C3.61032 2.57902 3.80109 2.5 4 2.5H16C16.1989 2.5 16.3897 2.57902 16.5303 2.71967C16.671 2.86032 16.75 3.05109 16.75 3.25V16ZM15.25 16V4H4.75V16H15.25ZM7 9.25H9.25V10.75H7V9.25ZM7 6.25H9.25V7.75H7V6.25ZM7 12.25H9.25V13.75H7V12.25ZM10.75 12.25H13V13.75H10.75V12.25ZM10.75 9.25H13V10.75H10.75V9.25ZM10.75 6.25H13V7.75H10.75V6.25Z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app/subscriptions",
      end: false,
      show: workspace?.slug == "admin",
      label: "Subscription Plans",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7501 7.75H16.7501L9.25006 19V12.25H4.00006L10.7501 1V7.75ZM9.25006 9.25V6.415L6.64906 10.75H10.7501V14.0455L13.9473 9.25H9.25006Z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app/transactions",
      end: false,
      show: workspace?.slug == "admin",
      label: "Transactions",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 17.5H4C3.80109 17.5 3.61032 17.421 3.46967 17.2803C3.32902 17.1397 3.25 16.9489 3.25 16.75V3.25C3.25 3.05109 3.32902 2.86032 3.46967 2.71967C3.61032 2.57902 3.80109 2.5 4 2.5H16C16.1989 2.5 16.3897 2.57902 16.5303 2.71967C16.671 2.86032 16.75 3.05109 16.75 3.25V16.75C16.75 16.9489 16.671 17.1397 16.5303 17.2803C16.3897 17.421 16.1989 17.5 16 17.5ZM15.25 16V4H4.75V16H15.25ZM7 6.25H13V7.75H7V6.25ZM7 9.25H13V10.75H7V9.25ZM7 12.25H13V13.75H7V12.25Z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app/templates",
      end: false,
      show: workspace?.slug == "admin",
      label: "Templates",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 1000 1000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M175 785 l-25 -24 0 -261 0 -261 25 -24 24 -25 301 0 301 0 24 25 25 24 0 261 0 261 -25 24 -24 25 -301 0 -301 0 -24 -25z m635 -15 c33 -33 26 -50
          -22 -52 l-43 -1 43 -4 42 -4 0 -230 c0 -216 -1 -230 -20 -249 -19 -19 -33 -20 -310 -20 -277 0 -291 1 -310 20 -19 19 -20 33 -20 250 l0 230 228 2 227 3
          -227 2 c-193 3 -228 6 -228 18 0 8 9 24 20 35 19 19 33 20 310 20 277 0 291 -1 310 -20z"
            fill="inherit"
          />
          <path
            d="M230 630 l0 -30 270 0 270 0 0 30 0 30 -270 0 -270 0 0 -30z m530 0
          c0 -19 -7 -20 -260 -20 -253 0 -260 1 -260 20 0 19 7 20 260 20 253 0 260 -1
          260 -20z"
            fill="inherit"
          />
          <path
            d="M230 425 l0 -135 125 0 125 0 0 135 0 135 -125 0 -125 0 0 -135z
          m240 0 l0 -125 -115 0 -115 0 0 125 0 125 115 0 115 0 0 -125z"
            fill="inherit"
          />
          <path
            d="M520 425 l0 -135 125 0 125 0 0 135 0 135 -125 0 -125 0 0 -135z
          m240 0 l0 -125 -115 0 -115 0 0 125 0 125 115 0 115 0 0 -125z"
            fill="inherit"
          />
        </svg>
      ),
    },
    {
      path: "/app/log",
      end: false,
      show: true,
      label: "Activity log",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 14.5002H8.125V15.4377C8.125 15.9847 7.9077 16.5093 7.52091 16.8961C7.13411 17.2829 6.60951 17.5002 6.0625 17.5002C5.51549 17.5002 4.99089 17.2829 4.60409 16.8961C4.2173 16.5093 4 15.9847 4 15.4377V14.5002ZM7.0435 11.5002L7.07725 11.4012C7.6525 9.82168 7.75 9.52768 7.75 9.25018C7.75 8.68768 7.59775 8.01793 7.354 7.54543C7.1725 7.19293 7.045 7.09018 7 7.09018C6.04 7.09018 5.125 8.11318 5.125 9.25018C5.125 9.96943 5.18125 10.5799 5.29525 11.3187L5.32375 11.5002H7.0435ZM7 5.59018C8.5 5.59018 9.25 7.75018 9.25 9.25018C9.25 10.0002 8.875 10.7502 8.5 11.8752L8.125 13.0002H4C4 12.2502 3.625 11.1252 3.625 9.25018C3.625 7.37518 5.1235 5.59018 7 5.59018ZM16.0405 11.5737L15.8778 12.4969C15.7828 13.0357 15.4777 13.5146 15.0295 13.8284C14.5814 14.1422 14.027 14.2652 13.4882 14.1702C12.9495 14.0752 12.4705 13.7701 12.1567 13.322C11.8429 12.8739 11.72 12.3194 11.815 11.7807L11.9785 10.8582L16.0405 11.5737ZM15.253 8.38918C15.3767 8.01418 15.4787 7.71793 15.4802 7.71418C15.6317 7.22068 15.751 6.73693 15.844 6.20818C16.0413 5.08918 15.3175 3.92293 14.3725 3.75568C14.3282 3.74818 14.185 3.82693 13.945 4.14193C13.6225 4.56568 13.357 5.19943 13.2587 5.75218C13.2152 5.99968 13.222 6.05593 13.4613 7.44943C13.495 7.64143 13.5228 7.81393 13.5483 7.98718L13.5632 8.09068L15.2537 8.38918H15.253ZM14.6335 2.27893C16.4815 2.60443 17.647 4.62193 17.3215 6.46843C16.996 8.31568 16.4313 9.35818 16.3015 10.0969L12.2387 9.38068L12.0648 8.20768C11.8907 7.03468 11.6523 6.23068 11.782 5.49268C12.0423 4.01518 13.156 2.01868 14.6335 2.27893Z"
            fill="inherit"
          />
        </svg>
      ),
    },
  ];
  return (
    // <motion.div initial={{ width: isCollapsed ? 120 : 272 }} className={`h-screen border-r lg:static absolute left-0 top-0 bg-white border-[#E2E4E9] z-10 flex flex-col shrink-0 transition-transform duration-300 lg:translate-x-0 ${isCollapsed ? 'w-[120px]' : 'w-[272px]'} ${show ? 'translate-x-0' : '-translate-x-full'}`}>
    <motion.div
      // animate={{ width: isCollapsed ? 60 : 272 }}
      className={`h-screen border-r lg:static absolute left-0 top-0 bg-white border-[#E2E4E9] z-10 flex flex-col shrink-0 ${
        isCollapsed ? "" : "w-[272px]"
      } transition-transform duration-300 lg:translate-x-0 ${
        show ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex flex-col border-b px-3 pt-5 pb-3 gap-y-6 w-full">
        <ProfileDropdown isCollpased={isCollapsed} />
      </div>
      <div className={`flex flex-col relative px-3 pt-3 gap-y-1`}>
        {links
          .filter((l) => l.show)
          .map((link) => (
            <div key={link.label} className="relative group">
              {pathname == link.path && (
                <motion.div
                  style={{ top: "25%" }}
                  layoutId="navtool"
                  className="absolute left-[-12px]"
                >
                  <svg
                    width="4"
                    height="20"
                    viewBox="0 0 4 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0C2.20914 0 4 1.79086 4 4V16C4 18.2091 2.20914 20 0 20V0Z"
                      fill="#5FD7F4"
                    />
                  </svg>
                </motion.div>
              )}
              <NavLink
                end={link.end}
                style={({ isActive }) => ({
                  background: isActive ? "#F1FBFE" : undefined,
                  color: isActive ? "#0A0D14" : "#525866",
                  fill: isActive ? "#39c9ee" : "#525866",
                })}
                className={`flex ${
                  isCollapsed ? "justify" : "items"
                }-center text-nowrap gap-x-2 px-2 py-3 rounded-lg text-sm hover:bg-[#F1FBFE] hover:fill-[#39c9ee] transition duration-200 ease-in-out`}
                to={link.path}
                onClick={() => {
                  pathname == link.path && setIsCollapsed((prev) => !prev);
                }}
              >
                {link.icon}
                <AnimatePresence>
                  {!isCollapsed && (
                    <motion.span
                      initial={{ width: 0, opacity: 0 }}
                      animate={{ width: "auto", opacity: 1 }}
                      exit={{ width: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {link.label}
                    </motion.span>
                  )}
                </AnimatePresence>
                {isCollapsed && (
                  <span className="absolute left-full ml-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded px-2 py-1">
                    {link.label}
                  </span>
                )}
              </NavLink>
            </div>
          ))}
      </div>
      {/* <div className='mt-auto flex w-full items-center justify-between p-3 border-t'>
        <span className='w-8 h-8 flex items-center justify-center bg-[#F6F8FA] rounded-[10px]'>
          <button
            className='cursor-pointer'
            onClick={() => setIsCollapsed((prev) => !prev)}
          >
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14ZM7.25 10.25H8.75V11.75H7.25V10.25ZM8.75 9.01625V9.5H7.25V8.375C7.25 8.17609 7.32902 7.98532 7.46967 7.84467C7.61032 7.70402 7.80109 7.625 8 7.625C8.21306 7.62499 8.42173 7.56447 8.60174 7.4505C8.78175 7.33652 8.9257 7.17377 9.01683 6.98119C9.10796 6.7886 9.14253 6.5741 9.11651 6.36263C9.0905 6.15117 9.00497 5.95144 8.86987 5.78668C8.73478 5.62193 8.55568 5.49892 8.35342 5.43198C8.15115 5.36503 7.93403 5.3569 7.72732 5.40853C7.52061 5.46016 7.33281 5.56942 7.18577 5.72361C7.03874 5.8778 6.93851 6.07057 6.89675 6.2795L5.42525 5.98475C5.51647 5.52881 5.72713 5.10528 6.03569 4.75744C6.34425 4.4096 6.73964 4.14994 7.18144 4.00499C7.62325 3.86004 8.09561 3.83501 8.55026 3.93246C9.00491 4.02991 9.42553 4.24634 9.76911 4.55962C10.1127 4.8729 10.3669 5.2718 10.5058 5.71555C10.6447 6.15929 10.6633 6.63196 10.5596 7.08523C10.456 7.5385 10.2338 7.95612 9.91589 8.2954C9.59794 8.63467 9.1956 8.88343 8.75 9.01625Z'
                fill='#525866'
              />
            </svg>
          </button>
        </span>
      </div> */}
    </motion.div>
  );
};
